import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../constants";
import { useAuth } from "../../../auth";

const Job = (props) => {
  const { data, onDelete } = props;
  const { role } = useAuth();

  function deleJob() {
    onDelete(data.id);
    window.$("#delete" + data.id).modal("hide");
  }
  return (
    <div {...props}>
      {role == "ROLE_AGENT" ? (
        <>
          <div className="d-flex mb-3  mt-2">
            <div className="col-lg-2">
              <span
                className="fa fa-briefcase"
                style={{ color: theme.color.default, fontSize: 70 }}
              ></span>
            </div>
            <div className="col-lg-8 ">
              <h5>
                {data.job_title.name} / {data.ref}
              </h5>
              <p dangerouslySetInnerHTML={{ __html: data.desciption }}></p>
            </div>
          </div>
          <div className="d-flex justify-content-between p-3">
            <div />
            <div>
              <span
                data-toggle="modal"
                data-target={"#delete" + data.id}
                className="fa fa-trash-o"
                style={{ color: "red", fontSize: 20 }}
              ></span>
            </div>
          </div>

          <div
            class="modal fade"
            id={"delete" + data.id}
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    TIA
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Voulez vous vraiment supprimer l'offre {data.name}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    class="btn bg-default text-white"
                    onClick={deleJob}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Link to={"/details/" + data.id} style={{ color: "#000" }}>
          <div className="d-flex mb-3  mt-2">
            <div className="col-lg-2">
              {data.image ? (
                <img
                  src={
                    "https://backend.grouptiajob.com/uploads/logos/" + data.image
                  }
                  width={100}
                  alt={data.nom_entreprise}
                />
              ) : (
                <span
                  className="fa fa-briefcase"
                  style={{ color: theme.color.default, fontSize: 70 }}
                ></span>
              )}
            </div>
            <div className="col-lg-8 ">
              <h5>{data.job_title.name}</h5>
              <p dangerouslySetInnerHTML={{ __html: data.desciption }}></p>
              <p>
                {data.skills.slice(0, 2).map((el, key) => {
                  return (
                    <button
                      type="button"
                      class="btn btn-outline-info btn-sm mr-1 mb-1"
                    >
                      {el.name}
                    </button>
                  );
                })}
              </p>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Job;
